
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu,  } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { Redirect } from 'react-router';


class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    logout = () => {
        sessionStorage.removeItem('authUser');
        this.setState({
          logout: true,
        });
      }


    render() {

        if (this.state.logout) {
            return <Redirect to='/' />;
          }
      
          return (
            <React.Fragment>
              <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                  <div className="rounded-circle header-profile-user"><span className=" fas fa-user font-size-24"></span></div>
                </DropdownToggle>
                <DropdownMenu right>
                  <Link
                    onClick={this.logout}
                    className="dropdown-item">
                    <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                    <span>Logout</span>
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </React.Fragment>
          );
        }
      }

export default withRouter(ProfileMenu);



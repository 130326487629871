import React, { Component, memo, useState, createRef, useEffect } from 'react';
import { Row, Col, Button, Alert, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import config from 'react-global-configuration';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Wrapper } from "@googlemaps/react-wrapper";
import Back from '../../components/Back';



const AddressField = memo(props => {

  const [address, setAddress] = useState(props.address)
  const [addressSelected, setAddressSelected] = useState(false)
  
  useEffect(() => {
    if (props.address !== '' && address !== ''){
      setAddress(props.address);
      props.parentCallback(props.address)
    }
  },[props.address]);

  const handleChange = myAddress => {

    setAddress(myAddress)
    props.parentCallback(myAddress)
  };

  if (props.address === "reset" && addressSelected){
    setAddress("")
    setAddressSelected(false)
  }

  const handleSelect = myAddress => {
    
    geocodeByAddress(myAddress)
      .then(results => getLatLng(results[0]))
      .then(
        (latLng) => {
          setAddress(myAddress)
          setAddressSelected(true)
          console.log('Success', latLng)
          console.log(myAddress)
          props.parentCallback(myAddress, latLng)
        }
      )
      .catch(error => console.error('Error', error));
    
  };


  return (
    <PlacesAutocomplete
    value={address}
    searchOptions={{
      componentRestrictions: {
         country: 'EE'
      }
    }}
    onChange={handleChange}
    onSelect={handleSelect}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div>
        <input
          {...getInputProps({
            placeholder: 'Sisesta aadress ...',
            className: 'location-search-input form-control',
          })}
        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Laen...</div>}
          {suggestions.map(suggestion => {
            const className = suggestion.active
              ? 'suggestion-item--active'
              : 'suggestion-item';
            // inline style for demonstration purpose
            const style = suggestion.active
              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
              : { backgroundColor: '#ffffff', cursor: 'pointer' };
            return (
              <div
                {...getSuggestionItemProps(suggestion, {
                  className,
                  style,
                })}
              >
                <span>{suggestion.description}</span>
              </div>
            );
          })}
        </div>
      </div>
    )}
  </PlacesAutocomplete>
    )

})

class Sensor extends Component {
  constructor(props) {
    super(props);
    
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));

    this.form = createRef()

    this.state = {
      authUser: authUser,
      object: '',
      showAlert: false,
      latLng: '',
      locationString: '',
      alertText: '',
      uuid: this.props.match.params.uuid
    };

    if (this.props.match.params.uuid) {
      this.getSensor();
    }
    
    
  }

  getSensor = () => {
    fetch(config.get('apiUrl')+'admin/sensor/'+this.props.match.params.uuid,{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        this.setState({
          object: data,
          latLng: {
            lat: data.coordinates.coordinates[0],
            lng: data.coordinates.coordinates[1]
          }
        });
      });


  }


    updateObjectAttribute = (attr, value) => {
      this.setState(prevState => ({
        object: {
          ...prevState.boat,
          [attr]:value
        }
      }));
    
    }

    updateObject = (event, values) => {
      let objectRequest = {
        uuid: this.state.uuid,
        title: values.title,
        longitude: values.longitude,
        latitude: values.latitude,
        locationString: values.address,
        serial: values.serial,
        alertLevel: values.alertLevel
      };
      fetch(config.get('apiUrl')+'admin/sensor',{
        method: 'PATCH',
        headers: {
          'Content-Type' : 'application/json',
          Authorization: 'Bearer '+ this.state.authUser.token
        },
        body: JSON.stringify({sensor: objectRequest})
      })
        .then(res=>res.json())
        .then((data)=> {
          this.setState({
            showAlert: true,
            alertText: data.message
          });
      
        });
    }

      createObject = (event, values) => {
        let objectRequest = {
          title: values.title,
          longitude: values.longitude,
          latitude: values.latitude,
          locationString: values.address,
          serial: values.serial,
          alertLevel: values.alertLevel
        };

        fetch(config.get('apiUrl')+'admin/sensor',{
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json',
            Authorization: 'Bearer '+ this.state.authUser.token
          },
          body: JSON.stringify({sensor: objectRequest})
        })
          .then(res=>res.json())
          .then((data)=> {
            console.log(data);
            if (data.error){
              this.setState({
                showAlert: true,
                alertText: data.error,
              })
            }
            else {
              this.setState({
                object: data.sensor,
                uuid: data.sensor.uuid,
                showAlert: true,
                alertText: 'Andur loodud',
              });
            }
    
          });

      }

      handleCallback = (myAddress, latLng) => {
        console.log(latLng)
        this.form.current._inputs['address'].value = myAddress
        this.setState({
          address: myAddress
        })
        if (latLng !== undefined){
          this.setState({
            latLng: latLng
          })
        }
  
      }

      render() {

        if (this.state.object === '') {
          return '';
        }

        return (
          <React.Fragment>
            <div className="container-fluid">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Andur {this.state.object.title}</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/#">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Andur</li>
                    </ol>
                  </div>
                </Col>

              </Row>

              <div className="row">
                <div className="col-12">
                  {this.state.showAlert ? <Alert color="success">{this.state.alertText}</Alert>: ''}
                  <div className="card">
                    <h4 className="card-title">{this.state.sensorUuid ? 'Sensor' : 'Loo uus sensor'}</h4>
                    <div className="card-body">
                    <Wrapper apiKey={"AIzaSyCJfM1FnaQoj3mNKce_6-ol3CabJKdNtDo"} libraries={['places']}>
                      <AvForm onValidSubmit={this.state.uuid ? this.updateObject : this.createObject} ref={this.form}>
                        <AvField
                          name="title"
                          label="Nimi"
                          grid={{sm:9}}
                          value={this.state.object.title}
                          placeholder="Sisesta sensori nimi"
                          type="text"
                          errorMessage="Sensori nimi on kohustuslik"
                          validate={{
                            required: { value: true }
                          }}
                        />
                        <AvField
                          name="serial"
                          label="Seeria number"
                          grid={{sm:9}}
                          value={this.state.object.serial}
                          placeholder="Sisesta sensori seeria number"
                          type="text"
                          errorMessage="Seeria number on kohustuslik"
                          validate={{
                            required: { value: true }
                          }}
                        />
                      <AvGroup>
                        <AvField
                        tag={AddressField}
                        name="address"
                        grid={{sm:9}}
                        label="Aadress"
                        type="text"
                        defaultValue={this.state.object.locationString}
                        address={this.state.object.locationString}
                        parentCallback={this.handleCallback}
                        />
                        <AvFeedback>Address on kohustuslik</AvFeedback>
                      </AvGroup>
                      <AvField
                          name="longitude"
                          label="Pikkuskraad"
                          grid={{sm:9}}
                          value={this.state.latLng.lng}
                          placeholder="Sisesta anduri asukoha pikkuskraad"
                          type="text"
                          errorMessage="Anduri asukoha pikkuskraad on kohustuslik"
                          validate={{
                            required: { value: true }
                          }}
                        />
                         <AvField
                          name="latitude"
                          label="Laiuskraad"
                          grid={{sm:9}}
                          value={this.state.latLng.lat}
                          placeholder="Sisesta anduri asukoha laiuskraad"
                          type="text"
                          errorMessage="Anduri asukoha laiuskraad on kohustuslik"
                          validate={{
                            required: { value: true }
                          }}
                        />
                        
                        <AvField
                          name="alertLevel"
                          label="Häire lävend"
                          grid={{sm:9}}
                          value={this.state.object.alertLevel}
                          placeholder="Sisesta häire lävendi väärtus"
                          type="text"
                          errorMessage="Häire lävend on kohustuslik"
                          validate={{
                            required: { value: true }
                          }}
                        />

                        <Row className="form-group">
                          <label
                            className="col-sm-3 col-form-label"
                          >
                          </label>
                          <Col className="col-form-label" sm={9}>
                            <Button color="primary" className="mr-1">
                              {this.state.uuid ? 'Salvesta' : 'Loo sensor' } 
                            </Button>
                            <Back/>
                          </Col>
                        </Row>
                      </AvForm>
                      </Wrapper>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </React.Fragment>
        );
      }
}

export default Sensor;

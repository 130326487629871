import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import config from 'react-global-configuration';


// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/authUtils";

//config.set({apiUrl: 'http://localhost:8085/api/v1/', appUrl: 'http://localhost:3000/'});
config.set({apiUrl: 'https://api.spengineers.eu/api/v1/', appUrl: 'https://iot.spengineers.eu/'});


// init firebase backend
initFirebaseBackend();



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    //this.loadMapsJs()
  }

  loadMapsJs = () => {
    var script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCJfM1FnaQoj3mNKce_6-ol3CabJKdNtDo&libraries=places&callback=initMap';
    script.defer = true;
  
    // Attach your callback function to the `window` object
    window.initMap = function() {
      // JS API is loaded and available
    };
  
    // Append the 'script' element to 'head'
    document.head.appendChild(script);
          
  }

  render() {

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);

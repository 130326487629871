import React, { Component, useRef, useEffect } from "react";
import { Row, Col, Button, Input, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { Wrapper } from "@googlemaps/react-wrapper";
import config from 'react-global-configuration';


const MyMapComponent = (props) => {
  const ref = useRef();
  const center = props.center
  const zoom = props.zoom
  const sensors = props.sensors
  useEffect(() => {
    const myMap = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
    let marker = {};
    sensors.forEach(element => {
      console.log(element)
      if (element.coordinates !== null){
      marker[element.id]= new window.google.maps.Marker({
        position: { lat: element.coordinates.coordinates[0], lng: element.coordinates.coordinates[1] },
        map: myMap,
        url: config.get('appUrl')+'measurements/1/'+element.serial,
        title: element.title,
        label: (element.temperature/10).toString()
      });
  
      window.google.maps.event.addListener(marker[element.id], 'click', function() {
        window.location.href = marker[element.id].url;
      });
    }
    });
    
  });

  

  return <div className="country-map" ref={ref} id="map" />;
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));
    this.state = {
      authUser: authUser,
      sensors: []
    };
    this.getSensors();
  }

  getSensors = () => {
    console.log(this.state.user);
    fetch(config.get('apiUrl')+'admin/sensors',{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        console.log(data);
        
        this.setState({
          sensors: data
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Töölaud</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item active">
                    Tere tulemast SP Töölauale 
                  </li>
                </ol>
              </div>

            </Col>

          </Row>
          <Row>
            <Col>
              <Wrapper apiKey={"AIzaSyCJfM1FnaQoj3mNKce_6-ol3CabJKdNtDo"} libraries={['places']}>
                <MyMapComponent center={{ lat: 58.6412472, lng: 25.2752302 }} zoom={7} sensors={this.state.sensors}/>
              </Wrapper>
            </Col>
          </Row>

        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;

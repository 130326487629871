import React, { Component } from 'react';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import {  Button } from 'reactstrap';

export default class LineChart extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			chartRef: React.createRef(),
			chart: Chart
		};
	
	  }

	componentDidMount() {
		const ctx = this.state.chartRef.current.getContext("2d");
		
		let theData = this.props.measurementData
		this.state.chart.register(zoomPlugin);

		this.setState({ 
			chart: new Chart(ctx, {
			type: "line",
			plugins: [{
				afterDraw: chart => {
				  var ctx = chart.ctx;
				  var xAxis = chart.scales['x'];
				  var yAxis = chart.scales['y'];
				  ctx.save();
				  chart.data.datasets[0].refLines.forEach(r => {
					var y = yAxis.getPixelForValue(r.y);
					ctx.strokeStyle = r.color;
					ctx.beginPath();
					ctx.moveTo(xAxis.left, y);
					ctx.lineTo(xAxis.right, y);
					ctx.stroke();
				  });
				  ctx.restore();
				},
				//decimation: decimation
			  }],
			data: {
			  datasets: [
				{
				  label: this.props.sensorName + ' (' + this.props.sensorUnit + ')',
				  data: theData,
				  fill: false,
				  indexAxis: 'x',
				  radius: 0,
				  backgroundColor: 'rgb(255, 99, 132)',
				  borderColor: 'rgba(255, 99, 132, 0.2)',
				  refLines: this.props.referenceLines
				}
			  ]
			},
			
			options: {
			  animation: false,
			  parsing: false,
			  plugins: {
				  zoom: {
				      zoom: {
				            drag: {
				              enabled: true
				            },
				            mode: 'x'
				          }
						
				  }
			  },
			  scales: {
				  x: {
					type: 'time',
					time: {
					  unit: this.props.selectedTimeRange.value === 168 ? 'hour' : 'minute',
					  displayFormats:{
						'millisecond': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
						'second': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'minute': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'hour': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'day': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'week': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
						'month': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
						'quarter': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
						'year': this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
					  },
					  tooltipFormat: 'DD.MM.YYYY HH:mm:ss'
					},
					ticks: {
						source: 'auto',
						// Disabled rotation for performance
						maxRotation: 0,
						autoSkip: true,
					  }
				  },
				y: {
					max: 100,
				}
	
			  }
			}
			
			},
		  )});

		  
	}
	render() {
		return (
			<div>
				<Button onClick={(evt) => {this.state.chart.resetZoom();}} color="primary" className="float-right" type="button" variant="outlined">
                            Tühista suurendus
                        </Button>
				<canvas
				id="myChart"
				ref={this.state.chartRef}
				/>
			</div>
			)
	}
	
}
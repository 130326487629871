import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import config from 'react-global-configuration';
import flatten from 'flat';
import moment from 'moment';


class Messages extends Component {
  constructor(props) {
    super(props);
    
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));
    this.state = {
      objects: [],
      authUser: authUser
    };

    this.getObjects();
  }
 

  handleRowClick = (id) => {
    console.log(id);
    this.props.history.push('/sensor/'+id);
  }

  getObjects = () => {
    fetch(config.get('apiUrl')+'admin/messages',{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        data.map(object => {
            object.createdAt = moment(object.createdAt).format('DD.MM.YYYY HH:mm:ss');
            object.message="";
          });
        
        Object.keys(data).forEach(function(key) {
            data[key] = flatten(data[key]);
          });
        console.log(data)
        this.setState({
          objects: data
        });
      });
  }

  render() {
    console.log(this.state.objects)
    const data = {
        columns: [
            {
                label: 'Nimi',
                field: 'alertnumber.name',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Telefoni number',
                field: 'alertnumber.phoneNumber',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Andur',
                field: 'alertnumber.sensor.title',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Aadress',
                field: 'alertnumber.sensor.locationString',
                sort: 'asc',
                width: 270
            },
            {
              label: 'Temperatuur',
              field: 'temperature',
              sort: 'asc',
              width: 270
          },
          {
            label: 'Saatmisaeg',
            field: 'createdAt',
            sort: 'asc',
            width: 270
        }
          ],
      rows: this.state.objects
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Saadetud sõnumid</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Töölaud</Link>
                  </li>
                  <li className="breadcrumb-item active">Saadetud sõnumid</li>
                </ol>
              </div>
            </Col>

          </Row>


          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">

                  <MDBDataTable 
                    responsive 
                    bordered 
                    data={data} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default Messages;

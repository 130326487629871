import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import LineChart from '../../components/LineChart';
//import { Chart, Line } from 'react-chartjs-2'
import Hammer from 'hammerjs';
import zoomPlugin from "chartjs-plugin-zoom";
import 'chartjs-adapter-moment';
import _ from 'lodash'
import moment from 'moment'


class Measurements extends React.Component {

    chartRef = React.createRef();

    constructor(props){
        super(props)
        let authUser = JSON.parse(sessionStorage.getItem('authUser'));
        let sensorName = '';
        let sensorUnit = '';
        let sensorFilter = 0;
        switch (this.props.match.params.id) {
          case "1":
            sensorName = 'Temperatuur';
            sensorUnit = 'C';
            sensorFilter = 2;
            break;
          default:
            break;
        }

        this.state = {
          forceUpdate: false,
          selectedSensor: '',
          availableSensors : [],
          sensorAlertLevels: [],
          measurementData: [],
          latestMeasurement:{},
          referenceLines: [ ],
          options: {},
          authUser: authUser,
          minDomain: 0,
          maxDomain: 1,
          sensorName: sensorName,
          sensorUnit: sensorUnit,
          sensorFilter: sensorFilter,
          loader: false,
          errorMessage: 'Palun vali andur',
          entireDomain : '',
          timeRanges: [
            {
              label: '1 tund',
              value: 1
            },
            {
              label: '1 päev',
              value: 24
            },
            {
              label: '1 nädal',
              value: 168
            },
            {
              label: '1 kuu',
              value: 672
            }

          ],
          selectedTimeRange:  {
            label: '1 päev',
            value: 24
          }
        }
       

    }

    componentDidMount = () => {
      //Chart.register(zoomPlugin)
      this.fetchSensors();
    }

    handleZoomReset = () => {
      this.lineReference.chartInstance.resetZoom()
  }


    static getDerivedStateFromProps(nextProps, prevState){
      if(nextProps.deviceSensors !== prevState.deviceSensors){
        return { deviceSensors: nextProps.deviceSensors};
     }
   }

   
  orderKeys = (obj, expected) => {

    var keys = Object.keys(obj).sort(function keyOrder(k1, k2) {
        if (k1 < k2) return -1;
        else if (k1 > k2) return +1;
        else return 0;
    });
  
    var i, after = {};
    for (i = 0; i < keys.length; i++) {
      after[keys[i]] = obj[keys[i]];
      delete obj[keys[i]];
    }
  
    for (i = 0; i < keys.length; i++) {
      obj[keys[i]] = after[keys[i]];
    }
    return obj;
  }

  fetchSensors = () => {
    fetch(config.get('apiUrl')+'admin/sensors',{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        let availableSensors = [];
        let sensorAlertLevels = []

        

        Object.keys(data).forEach((key) => {

          if (this.props.match.params.serial !== 0 && data[key].serial === this.props.match.params.serial){
            this.handleSensorChange({value: this.props.match.params.serial, label: data[key].title},data[key].alertLevel)
          }
          availableSensors.push(
            {
              label: data[key].title,
              value: data[key].serial
            }
          );
          sensorAlertLevels[data[key].serial] = data[key].alertLevel
        });
        this.setState({
          availableSensors,
          sensorAlertLevels
        });
      });
  }


   componentDidUpdate(prevProps, prevState) {

      if(prevProps.deviceSensors!==this.props.deviceSensors){
        this.fetchSensors();
      }

      if (prevProps.match.params.id !== this.props.match.params.id){
        let sensorName = '';
        let sensorUnit = '';
        let sensorFilter = 0
        switch (this.props.match.params.id) {
          case "1":
            sensorName = 'Temperatuur';
            sensorUnit = 'C';
            sensorFilter = 2;
            break;
          default:
            break;
        }

        this.setState({
          sensorName: sensorName,
          sensorUnit: sensorUnit,
          sensorFilter: sensorFilter,
          measurementData: {},
          selectedSensor: ''
        });
        this.fetchSensors();
      }
    }

    handleSensorChange = (sensor, alertLevel) => {
      console.log(alertLevel)
      if (alertLevel.action === "select-option"){
        alertLevel = this.state.sensorAlertLevels[sensor.value]
      }
      this.setState({
        selectedSensor: sensor,
        referenceLines: [
          { y: alertLevel, color: '#0be059' }
        ]
      })
      this.getMeasurements(sensor.value, this.state.selectedTimeRange.value);
      this.getLatestMeasurement(sensor.value)
  
    }

    handleTimeRangeChange = (timeRange) => {
      this.setState({
        selectedTimeRange: timeRange
      })
      this.getMeasurements(this.state.selectedSensor.value, timeRange.value);
  
    }

    getData = () => {
      return this.state.data.filter(
        // is d "between" the ends of the visible x-domain?
        (d) => (d.x >= this.state.zoomDomain[0] && d.x <= this.state.zoomDomain[1]));
    }

    getEntireDomain = (data) => {
      return {
        y: [_.minBy(data, d => d.b).b*0.95, _.maxBy(data, d => d.b).b*1.05],
        x: [ data[0].a, _.last(data).a ]
      };
    }

    getLatestMeasurement = (sensor) => {
      fetch(config.get('apiUrl')+'admin/latestMeasurement/'+sensor,{
        headers: {
          Authorization: 'Bearer '+ JSON.parse(sessionStorage.getItem('authUser')).token
        }
      })
        .then(res=>res.json())
        .then((data)=> {
            this.setState({
              latestMeasurement: data
            })
        });
    } 


    getMeasurements = (sensor, hours) => {
      this.setState({
        loader: true
      })
      fetch(config.get('apiUrl')+'admin/measurements/'+sensor+'/'+hours,{
        headers: {
          Authorization: 'Bearer '+ JSON.parse(sessionStorage.getItem('authUser')).token
        }
      })
        .then(res=>res.json())
        .then((data)=> {
          if (Object.keys(data).length) {
            let theData = [];
            let theLabels = [];
            let lastTimestamp = 0;
            let minimumValue = "";
            let maximumValue = "";
            Object.keys(data).forEach((key) => {      
                if (data[key].value / 10 < 200){  
                  theData.push({x: data[key].measuredAt*1000, y: data[key].value / 10 })
                  theLabels.push(data[key].measuredAt*1000)
                }
            });
            
            //minimumValue = minimumValue * 3;
            //maximumValue = maximumValue * 3;

            console.log(this.chartRef)
            

            this.setState({
              //entireDomain: theData.length > 0 ? this.getEntireDomain(theData) : '',
              measurementData: theData,
              //minDomain: minimumValue,
              maxDomain: 100,
              loader: false
            })
        }
        else {
          this.setState({
            errorMessage: 'Valitud perioodi kohta puuduvad mõõteandmed',
            loader: false,
            measurementData: {}
          })
        }
        });
    } 

    render() {
        const { t } = this.props;
        let yAxes = {};
        let decimation = {
          enabled: true,
            algorithm: 'lttb',
            samples: 5000
        };
        if (this.props.match.params.id === '3'){
          yAxes = {
            type: 'category',
            labels: ['on','off']
          }
        }
        if (this.state.selectedTimeRange.value > 1) {
          decimation = {
            enabled: true,
            algorithm: 'lttb',
            samples: 5000
          }
        }

        let mutableData = {};
        mutableData = Object.create(this.state.measurementData)


        const options = {
          animation: false,
          parsing: false,
          scales: {
              x: {
                type: 'time',
                lineTension: 0,
                distribution: 'linear',
                time: {
                  unit: this.state.selectedTimeRange.value === 168 ? 'hour' : 'minute',
                  displayFormats:{
                    'millisecond': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
                    'second': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'minute': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'hour': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'day': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'week': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
                    'month': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',  
                    'quarter': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
                    'year': this.state.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm', 
                  },
                  tooltipFormat: 'DD.MM.YYYY HH:mm:ss'
                },
              },
            y: yAxes

          }
        }

        return  <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">{this.state.sensorName}i lugemid</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Töölaud</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.sensorName}i lugemid</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                    Andur: 
                    <Select
                        value={this.state.selectedSensor}
                        isClearable={false}
                        placeholder="Vali andur"
                        onChange={this.handleSensorChange}
                        options={this.state.availableSensors}
                      />
                    </div>
                    <div className="col-4">
                    Ajaperiood:
                    <Select
                        value={this.state.selectedTimeRange}
                        isClearable={false}
                        onChange={this.handleTimeRangeChange}
                        options={this.state.timeRanges}
                      />
                    </div>
                    <div className="col-4">
                      Viimane lugem: <br/>{this.state.latestMeasurement.value/10}&deg;C @ {moment(this.state.latestMeasurement.measuredAt*1000).format('DD.MM.YYYY HH:mm:ss')}
                      <br/>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                
                 
                <Container className="page-container"> 
                {this.state.measurementData.length > 0 ? 
                  <Col className="content">
                    {this.state.loader === false ? 
                  <LineChart 
                      sensorName={this.state.sensorName} 
                      sensorUnit={this.state.sensorUnit} 
                      selectedTimeRange={this.state.selectedTimeRange}
                      referenceLines={this.state.referenceLines}
                      measurementData={this.state.measurementData}/>
                    : <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Laen andmeid...</span>
             </div>
    }
      {/* 
                    <Line 
                      data={this.state.measurementData} 
                      options={options} 
                      ref={(reference) => this.lineReference = reference}/> */}

          
                  </Col> : this.state.errorMessage }
        </Container> 
            
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
        
       
        
    }
}

  export default Measurements

import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import config from 'react-global-configuration';

class Sensors extends Component {
  constructor(props) {
    super(props);
    
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));
    this.state = {
      sensors: [],
      authUser: authUser
    };

    this.getSensors();
  }
 

  handleRowClick = (id) => {
    console.log(id);
    this.props.history.push('/sensor/'+id);
  }

  getSensors = () => {
    console.log(this.state.user);
    fetch(config.get('apiUrl')+'admin/sensors',{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        console.log(data);

        data.map(sensor => {
          sensor.clickEvent = e => this.handleRowClick(sensor.uuid);
          return null;
        });

        
        this.setState({
          sensors: data
        });
      });
  }

  render() {
    const data = {
      columns: [
        {
            label: 'Nimi',
            field: 'title',
            sort: 'asc',
            width: 270
        },
        {
            label: 'Seeria nr',
            field: 'serial',
            sort: 'asc',
            width: 270
        },
        {
            label: 'Asukoht',
            field: 'locationString',
            sort: 'asc',
            width: 270
        },
      ],
      rows: this.state.sensors
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Andurid</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Töölaud</Link>
                  </li>
                  <li className="breadcrumb-item active">Andurid</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Link to="/newsensor" className="waves-effect">
                    <Button color="primary" className="mr-1">
                               Lisa andur
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">

                  <MDBDataTable 
                    responsive 
                    bordered 
                    className="rows-clickable"
                    data={data} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default Sensors;

import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/FogetPassword";

import Dashboard from "../pages/Dashboard";
import Sensors from "../pages/Sensors";
import Sensor from "../pages/Sensors/Sensor";
import AlertContacts from "../pages/AlertContacts";
import AlertContact from "../pages/AlertContacts/AlertContact";
import SMSTemplate from "../pages/Sms";
import Measurements from "../pages/Measurements";
import Messages from "../pages/Messages";
import Users from "../pages/Users";
import User from "../pages/Users/User";


const authProtectedRoutes = [
  // Calendar
 

  { path: "/dashboard", component: Dashboard },
  { path: "/sensors", component: Sensors},
  { path: "/sensor/:uuid", component: Sensor},
  { path: "/newsensor", component: Sensor},
  { path: "/alertcontacts", component: AlertContacts},
  { path: "/alertcontact/:uuid", component: AlertContact},
  { path: "/smstemplates", component: SMSTemplate},
  { path: "/measurements/:id/:serial", component: Measurements},
  { path: "/messages", component: Messages},
  { path: "/users", component: Users},
  { path: "/user/:uuid", component: User},
  { path: "/newuser", component: User},

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forget-password", component: ForgetPwd },
  { path: "/pages-register", component: Register },

];

export { authProtectedRoutes, publicRoutes };

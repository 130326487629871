import React, { Component } from 'react';
import { Row, Col, Button, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Modal } from 'reactstrap';
import config from 'react-global-configuration';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Redirect } from 'react-router';
import Back from '../../components/Back';

class AlertContact extends Component {
  constructor(props) {
    super(props);
    
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));

    this.state = {
      authUser: authUser,
      object: '',
      alarmObject: [],
      sensors: [],
      showAlert: false,
      alertText: '',
      modal_delete: false,
      type: this.props.match.params.uuid === "1" || this.props.match.params.uuid === "2" ? parseInt(this.props.match.params.uuid) : 0,
      title: this.props.match.params.uuid !== "2" ? 'Koduomanik' : 'Häirekoordinaator',
      uuid: this.props.match.params.uuid,
      id: '',
      deleted: false
    };
    this.getSensors();
    if (this.props.match.params.uuid !== "1" && this.props.match.params.uuid !== "2") {
      this.getObject();
    }
    else  if (this.props.match.params.uuid === "2"){
      this.getAlarmObject();
    }
    
    
  }

  getObject = () => {
    fetch(config.get('apiUrl')+'admin/alertContact/'+this.props.match.params.uuid,{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {

       let title = data.type === 1 ? 'Koduomanik' : 'Häirekoordinaator';

        this.setState({
          object: data,
          title,
          id: data.id,
          type: data.type
        });
      });
  }

  getAlarmObject = () => {
    fetch(config.get('apiUrl')+'admin/alarmContact/',{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {

       let title = 'Häirekoordinaator';

        this.setState({
          alarmObject: data,
          title,
          type: 2
        });
      });
  }

  getSensors = () => {
    fetch(config.get('apiUrl')+'admin/sensors',{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        
        this.setState({
          sensors: data
        });
      });
  }



    updateObjectAttribute = (attr, value) => {
      this.setState(prevState => ({
        object: {
          ...prevState.boat,
          [attr]:value
        }
      }));
    
    }

    updateAlarmContact = (event, values) => {
      let objectRequest = [{
        id: this.state.alarmObject[0] !== undefined ? this.state.alarmObject[0].id : '',
        phoneNumber: values.phoneNumber
      },
      {
        id: this.state.alarmObject[1] !== undefined ? this.state.alarmObject[1].id : '',
        phoneNumber: values.phoneNumber2
      }];
      fetch(config.get('apiUrl')+'admin/alarmContact',{
        method: 'PATCH',
        headers: {
          'Content-Type' : 'application/json',
          Authorization: 'Bearer '+ this.state.authUser.token
        },
        body: JSON.stringify({alarmContact: objectRequest})
      })
        .then(res=>res.json())
        .then((data)=> {
          this.setState({
            showAlert: true,
            alertText: data.message
          });
      
        });
    }

    updateObject = (event, values) => {

      if (this.state.type === 2) {
        this.updateAlarmContact(event, values);
      }
      else {
        let objectRequest = {
          id: this.state.id,
          name: values.name,
          phoneNumber: values.phoneNumber,
          idsensor: values.sensors
        };
        fetch(config.get('apiUrl')+'admin/alertContact',{
          method: 'PATCH',
          headers: {
            'Content-Type' : 'application/json',
            Authorization: 'Bearer '+ this.state.authUser.token
          },
          body: JSON.stringify({alertContact: objectRequest})
        })
          .then(res=>res.json())
          .then((data)=> {
            this.setState({
              showAlert: true,
              alertText: data.message
            });
        
          });
      }
      
    }

      createObject = (event, values) => {
        let objectRequest = {
          name: values.name,
          phoneNumber: values.phoneNumber,
          type: this.state.type,
          idsensor: values.sensors
        };

        fetch(config.get('apiUrl')+'admin/alertContact',{
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json',
            Authorization: 'Bearer '+ this.state.authUser.token
          },
          body: JSON.stringify({alertContact: objectRequest})
        })
          .then(res=>res.json())
          .then((data)=> {
            console.log(data);
            this.setState({
              object: data.alertContact,
              uuid: data.alertContact.uuid,
              showAlert: true,
              alertText: 'Kontakt loodud',
            });
    
          });

      }

      deleteObject = () => {
        fetch(config.get('apiUrl')+'admin/alertContact/'+ this.props.match.params.uuid,{
          method: 'DELETE',
          headers: {
            'Content-Type' : 'application/json',
            Authorization: 'Bearer '+ this.state.authUser.token
          }
        })
          .then(res=>res.json())
          .then((data)=> {
            console.log(data);
            if (data.status === 'deleted'){
              this.setState({deleted: true});
            }
      
          });
      }

      render() {

       

        if (this.state.deleted) {
          return <Redirect to='/alertContacts' />;
        }

        return (
          <React.Fragment>
            <div className="container-fluid">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Kontakt ({this.state.title})</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/#">Töölaud</Link>
                      </li>
                      <li className="breadcrumb-item active">Kontakt ({this.state.title})</li>
                    </ol>
                  </div>
                </Col>

              </Row>

              <div className="row">
                <div className="col-12">
                  {this.state.showAlert ? <Alert color="success">{this.state.alertText}</Alert>: ''}
                  <div className="card">
                    <h4 className="card-title">{this.state.uuid ? 'Kontakt' : 'Loo uus kontakt'}</h4>
                    <div className="card-body">
                      <AvForm onValidSubmit={this.state.uuid === '1'  ? this.createObject : this.updateObject}>
                        { this.state.type === 1 ?
                          <AvField
                            name="name"
                            label="Nimi"
                            grid={{sm:9}}
                            value={this.state.object.name}
                            placeholder="Sisesta kontakti nimi"
                            type="text"
                            errorMessage="Kontakti nimi on kohustuslik"
                            validate={{
                              required: { value: true }
                            }}
                          /> : ''
                        }
                        <AvField
                          name="phoneNumber"
                          label="Telefoninumber"
                          grid={{sm:9}}
                          value={this.state.type === 2 ? this.state.alarmObject[0] === undefined ? '' : this.state.alarmObject[0].phoneNumber : this.state.object.phoneNumber}
                          placeholder="Sisesta telefoninumber"
                          type="text"
                          errorMessage="Telefoninumber on kohustuslik"
                          validate={{
                            required: { value: true }
                          }}
                        />
                        {this.state.type === 2 ? 
                        <AvField
                        name="phoneNumber2"
                        label="Telefoninumber 2"
                        grid={{sm:9}}
                        value={this.state.alarmObject[1] === undefined ? '' : this.state.alarmObject[1].phoneNumber}
                        placeholder="Sisesta telefoninumber"
                        type="text"
                        errorMessage="Telefoninumber on kohustuslik"
                        
                        /> : ''}
                        { this.state.type === 1 ?
                         <AvField 
                          type="select" 
                          defaultValue={this.state.object.idsensor} 
                          value={this.state.object.idsensor}
                          name="sensors" 
                          grid={{sm:9}}
                          label="Andurid"
                          validate={{
                            required: { value: true}
                          }}
                        >
                          <option value="0">Vali andur</option>
                          {
                            
                            this.state.sensors.map((type, idx) => <option value={type.id}>{type.title}</option>
                            )
                          }
                        </AvField>
                        : ''}

                        <Row className="form-group">
                          <label
                            className="col-sm-3 col-form-label"
                          >
                          </label>
                          <Col className="col-form-label" sm={9}>
                            <Button color="primary" className="mr-1">
                              {this.state.uuid ? 'Salvesta' : 'Loo kontakt' } 
                            </Button>
                            {this.state.object.type !== 2 && this.state.object.type !== undefined ?
                            <Button color="secondary" onClick={()=>this.setState({modal_delete: true})}>
                              Kustuta
                            </Button>
                            : '' }
                            <Back/>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <Modal
          isOpen={this.state.modal_delete}
          toggle={this.tog_center}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Kustutamise kinnitus</h5>
            <button
              type="button"
              onClick={() =>
                this.setState({ modal_delete: false })
              }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
                Kas oled kindel, et soovid kontakti kustutada?
            </p>
            <p>
              <button type="button" className="btn btn-primary" onClick={()=>this.deleteObject()}>
                Kustuta
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() =>
                  this.setState({ modal_delete: false })
                }
              >
                Loobu
              </button>
            </p>
          </div>
        </Modal>
          </React.Fragment>
        );
      }
}

export default AlertContact;

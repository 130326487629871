import React, { Component } from 'react';
import { Row, Col, Button, Alert, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import config from 'react-global-configuration';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import Back from '../../components/Back';


class SMSTemplate extends Component {
  constructor(props) {
    super(props);
    
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));

    this.state = {
      authUser: authUser,
      object: '',
      showAlert: false,
      alertText: '',
    };

    this.getObject();
    
    
  }

  getObject = () => {
    fetch(config.get('apiUrl')+'admin/messageTemplate/',{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        console.log(data)
        this.setState({
          object: data
        });
      });


  }


    updateObjectAttribute = (attr, value) => {
      this.setState(prevState => ({
        object: {
          ...prevState.boat,
          [attr]:value
        }
      }));
    
    }

    updateObject = (event, values) => {
      let objectRequest = [
        {
          id: this.state.object[0].id,
          message: values.message1,
        },
        {
          id: this.state.object[1].id,
          message: values.message2,
        }
      ];
      fetch(config.get('apiUrl')+'admin/messageTemplate',{
        method: 'PATCH',
        headers: {
          'Content-Type' : 'application/json',
          Authorization: 'Bearer '+ this.state.authUser.token
        },
        body: JSON.stringify({messageTemplate: objectRequest})
      })
        .then(res=>res.json())
        .then((data)=> {
            if (data.error){
                this.setState({
                  showAlert: true,
                  alertText: data.error,
                })
              }
              else {
                this.setState({
                    showAlert: true,
                    alertText: data.message
                });
              }
      
        });
    }


      render() {

        if (this.state.object === ''){
            return (
              <React.Fragment></React.Fragment>
            )
        }
       
        return (
          <React.Fragment>
            <div className="container-fluid">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">SMS Šabloonid</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/#">Töölaud</Link>
                      </li>
                      <li className="breadcrumb-item active">SMS Šabloonid</li>
                    </ol>
                  </div>
                </Col>

              </Row>

              <div className="row">
                <div className="col-12">
                  {this.state.showAlert ? <Alert color="success">{this.state.alertText}</Alert>: ''}
                  <div className="card">
                    <div className="card-body">
                      <AvForm onValidSubmit={this.updateObject}>
                        <AvField
                          name="message1"
                          label="Koduomaniku SMS šabloon"
                          grid={{sm:9}}
                          value={this.state.object[0].message}
                          placeholder="Sisesta SMS sõnumi šabloon"
                          type="textarea"
                          rows={4}
                          errorMessage="SMS sõnumi šabloon on kohustuslik"
                          validate={{
                            required: { value: true }
                          }}
                        />

                        <AvField
                          name="message2"
                          label="Häirekoordinaatori SMS šabloon"
                          grid={{sm:9}}
                          value={this.state.object[1].message}
                          placeholder="Sisesta SMS sõnumi šabloon"
                          type="textarea"
                          rows={4}
                          errorMessage="SMS sõnumi šabloon on kohustuslik"
                          validate={{
                            required: { value: true }
                          }}
                        />
                        

                        <Row className="form-group">
                          <label
                            className="col-sm-3 col-form-label"
                          >
                          </label>
                          <Col className="col-form-label" sm={9}>
                            <Button color="primary" className="mr-1">
                               Salvesta 
                            </Button>
                            <Back/>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </React.Fragment>
        );
      }
    
}

export default SMSTemplate;

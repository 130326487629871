import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import config from 'react-global-configuration';
import flatten from 'flat';

class AlertContacts extends Component {
  constructor(props) {
    super(props);
    
    let authUser = JSON.parse(sessionStorage.getItem('authUser'));
    this.state = {
      objects: [],
      authUser: authUser,
      alarmCoordinatorId: 2
    };

    this.getObjects();
  }
 

  handleRowClick = (id) => {
    console.log(id);
    this.props.history.push('/alertContact/'+id);
  }

  getObjects = () => {
    console.log(this.state.user);
    fetch(config.get('apiUrl')+'admin/alertContacts',{
      headers: {
        Authorization: 'Bearer '+ this.state.authUser.token
      }
    })
      .then(res=>res.json())
      .then((data)=> {
        console.log(data);

        data.map(object => {
          if (object.type === 2) {
            this.setState({alarmCoordinatorId: object.uuid})
          }
          object.clickEvent = e => this.handleRowClick(object.type === 1 ? object.uuid : 2);
          object.name = object.type === 1 ? object.name : 'Häirekoordinaator';
          object.type_title = object.type === 1 ? 'Koduomanik' : 'Häirekoordinaator';
          
          return null;
        });

        Object.keys(data).forEach(function(key) {
            data[key] = flatten(data[key]);
          });

        
        this.setState({
          objects: data
        });
      });
  }

  render() {
    const data = {
      columns: [
        {
            label: 'Nimi',
            field: 'name',
            sort: 'asc',
            width: 270
        },
        {
            label: 'Telefoni number',
            field: 'phoneNumber',
            sort: 'asc',
            width: 270
        },
        {
          label: 'Tüüp',
          field: 'type_title',
          sort: 'asc',
          width: 270
        },
        {
            label: 'Andur',
            field: 'sensor.title',
            sort: 'asc',
            width: 270
        },
      ],
      rows: this.state.objects
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Häire kontaktid</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Töölaud</Link>
                  </li>
                  <li className="breadcrumb-item active">Häire kontaktid</li>
                </ol>
              </div>
            </Col>

          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Link to="/alertcontact/1" className="waves-effect">
                    <Button color="primary" className="mr-1">
                               Lisa koduomaniku kontakt
                    </Button>
                  </Link>
                  <Link to={'/alertcontact/2'} className="waves-effect">
                    <Button color="primary" className="mr-1">
                               Lisa häirekoordinaatori kontakt
                    </Button>
                  </Link>
                </div>
                
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">

                  <MDBDataTable 
                    responsive 
                    bordered 
                    className="rows-clickable"
                    data={data} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default AlertContacts;
